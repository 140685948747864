/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/12/2020.
 */

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import classnames from "classnames";
import Events from "./Events";
import CalendarHeaderLabel from "../CalendarHeader/CalendarHeaderLabel";
import PoweredBy from "./../PoweredBy";
import sleep from "../../../../helpers/sleep";
import { useTranslation } from "react-i18next";
import { removeEventUrlQueryParams } from "../../../../helpers/calendar";
import { WT } from "../../../admin/consts/widget";
import { useHistory } from "react-router-dom";

const inlineStyle = { position: "relative" };

const EventsListModal = React.memo((props) => {
  const [modalDom] = useState(document.createElement("div"));
  const calendarID = "dce_calendar__" + props.calendar.model.uuid;
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const selectedEvent = props.selectedEvent;

  useEffect(() => {
    document.body.appendChild(modalDom);

    return () => {
      history.push(removeEventUrlQueryParams([WT.calendar, "event"]));
      document.body.removeChild(modalDom);
    };
  }, []);

  /**
   *
   * @param e
   */
  const onModalAreaClick = (e) => {
    const eventContainer = document.querySelector(
      "#modal-events-list-container"
    );

    const eventModal = document.getElementById("modal-event-container");

    if (!eventModal && !eventContainer.contains(e.target)) {
      onEventsRowClick();
    }

    return false;
  };

  /**
   *
   */
  const onEventsRowClick = () => {
    setStatePromise(setVFXState, {
      showWrapper: false,
      showContainer: false,
    })
      .then(() => sleep(150))
      .then(() => {
        document.body.style.overflow = "auto";
        props.closeOpenedEventsDetails();
      });
  };

  /**
   *
   */
  const onClosedEventRowModal = () => {
    if (selectedEvent) {
      onEventsRowClick();
    }
  };

  /**
   *
   * @param setState
   * @param state
   * @returns {Promise<void>}
   */
  const setStatePromise = (setState, state) => {
    setState(state);
    return Promise.resolve();
  };

  const [VFXState, setVFXState] = useState({
    showWrapper: false,
    showContainer: false,
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setStatePromise(setVFXState, { showWrapper: true, showContainer: false })
      .then(() => sleep(150))
      .then(() => {
        setStatePromise(setVFXState, {
          showWrapper: true,
          showContainer: true,
        });
      });
  }, []);

  const { events, calendar } = props;

  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <div className={"d-flex justify-content-center"}>
        <PoweredBy />
      </div>
    ) : null;

  return ReactDOM.createPortal(
    <div
      className={classnames("events-list-modal--wrapper", calendarID, {
        show: VFXState.showWrapper,
      })}
      id="modal-events-list-wrapper"
      onClick={onModalAreaClick}
      lang={i18n.language}
    >
      <div className={"header-wrapper"}>
        <a className={"back-button"} onClick={onModalAreaClick} href={"#"}>
          <i className="material-icons">keyboard_arrow_left</i>&nbsp;
          {t("Back to calendar")}
        </a>
        <div className={"month-selector"}>
          <CalendarHeaderLabel
            currentDate={props.currentDate}
            show={true}
            events={props.events}
            showDayName={true}
          />
        </div>
      </div>
      <div
        className={classnames("event-list-modal--container", {
          show: VFXState.showContainer,
        })}
        id={"modal-events-list-container"}
      >
        <div style={inlineStyle}>
          <Events
            events={props.events}
            calendarSlug={calendar.model.slug}
            embedded={props.embedded}
            ccbAddress={calendar.model.integration.address}
            onRSVPEvent={props.onRSVPEvent}
            loading={false}
            error={props.calendar.rsvpError}
            onLoadMore={null}
            hasMore={false}
            selectedEvent={selectedEvent}
            onClosedEventRowModal={onClosedEventRowModal}
            calendarID={calendarID}
          />
        </div>
      </div>
      {watermark}
    </div>,
    modalDom
  );
});

EventsListModal.defaultProps = {};

EventsListModal.propTypes = {
  calendar: PropTypes.object.isRequired,
  events: PropTypes.array,
  embedded: PropTypes.bool,
  closeOpenedEventsDetails: PropTypes.func.isRequired,
  ccbAddress: PropTypes.string.isRequired,
  onRSVPEvent: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  currentDate: PropTypes.object,
};
//Row.whyDidYouRender = true
export default EventsListModal;
